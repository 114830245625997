import { FunctionComponent } from 'react';

const RunningText: FunctionComponent = () => {
  return (
    <div className="self-stretch bg-gray-600 [backdrop-filter:blur(4px)] overflow-hidden flex flex-row items-start justify-start pt-[18px] px-0 pb-[19px] box-border max-w-full text-left text-5xl text-gray-400 font-inter">
      <div className="ml-[-1346px] w-[2806px] flex flex-row items-start justify-start relative gap-[20px] shrink-0 [debug_commit:f6aba90] mq1350:flex-wrap">
        <div className="w-[406px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
          <div className="self-stretch h-[29px] relative uppercase font-light inline-block mq450:text-lgi">
            new way to reach the new fan
          </div>
        </div>
        <div className="w-[907px] flex flex-col items-start justify-start pt-0.5 px-0 pb-0 box-border max-w-full">
          <div className="w-[538px] flex flex-row items-start justify-start py-0 pr-5 pl-0 box-border gap-[20px] max-w-full mq800:flex-wrap">
            <img
              className="h-9 w-9 relative overflow-hidden shrink-0 min-h-[36px]"
              loading="lazy"
              alt=""
              src="/globe-4.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border min-w-[264px] max-w-full">
              <div className="self-stretch h-[29px] relative uppercase font-light inline-block mq450:text-lgi">
                new way to reach the new fan
              </div>
            </div>
            <img className="h-9 w-9 relative overflow-hidden shrink-0" loading="lazy" alt="" src="/globe-4.svg" />
          </div>
        </div>
        <img className="h-9 w-9 relative overflow-hidden shrink-0 hidden z-[2]" alt="" src="/globe-2.svg" />
        <img className="h-9 w-9 relative overflow-hidden shrink-0 hidden z-[3]" alt="" src="/globe-2.svg" />
        <img className="h-9 w-9 relative overflow-hidden shrink-0 hidden z-[4]" alt="" src="/globe-2.svg" />
        <div className="flex flex-row items-start justify-start gap-[54px] max-w-full font-azeret-mono mq800:gap-[54px_27px] mq1350:flex-wrap">
          <div className="h-[39px] flex flex-col items-start justify-start pt-px pb-0 pr-[7px] pl-0 box-border">
            <img
              className="w-8 h-[38px] relative object-cover mix-blend-luminosity"
              loading="lazy"
              alt=""
              src="/dijestify-ai-logo-4@2x.png"
            />
          </div>
          <div className="flex flex-col items-start justify-start pt-[5px] pb-0 pr-[7px] pl-0 box-border max-w-full">
            <div className="relative uppercase font-light mq450:text-lgi">JUST SAVE, GET SUMMARY</div>
          </div>
          <div className="h-[38px] flex flex-col items-start justify-start py-0 pr-[7px] pl-0 box-border">
            <img
              className="w-8 h-[38px] relative object-cover mix-blend-luminosity"
              loading="lazy"
              alt=""
              src="/dijestify-ai-logo-4@2x.png"
            />
          </div>
          <div className="flex flex-col items-start justify-start pt-[5px] pb-0 pr-3.5 pl-0 box-border max-w-full">
            <div className="relative uppercase font-light mq450:text-lgi">JUST SAVE, GET SUMMARY</div>
          </div>
          <img
            className="h-[38px] w-8 relative object-cover mix-blend-luminosity"
            loading="lazy"
            alt=""
            src="/dijestify-ai-logo-4@2x.png"
          />
          <div className="flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
            <div className="relative uppercase font-light mq450:text-lgi">JUST SAVE, GET SUMMARY</div>
          </div>
        </div>
        <img
          className="h-[38px] w-8 absolute !m-[0] top-[0px] right-[0px] object-cover mix-blend-luminosity"
          loading="lazy"
          alt=""
          src="/dijestify-ai-logo-4@2x.png"
        />
      </div>
    </div>
  );
};

export default RunningText;

import { FunctionComponent } from 'react';
import RunningText from '../components/RunningText';

const ChromeExtensionLandingPage: FunctionComponent = () => {
  return (
    <div className="w-full h-full relative flex flex-col items-end justify-start pt-[104px] mq800:pt-[0px] px-0 pb-0 box-border tracking-[normal] mq800:h-auto">
      <section className="mq800:mt-[0px] mt-[-370px] w-full bg-gray-200 overflow-hidden flex flex-col items-start justify-start pt-[266px] pb-0 pr-[0.3000000000001819px]  box-border relative gap-[40px] max-w-[116%] shrink-0 text-left text-29xl text-white font-azeret-mono mq450:pl-5 mq450:pt-[47px] mq450:box-border mq800:gap-[20px_40px] mq800:pl-[0px] mq800:pt-[0px] mq800:box-border mq1350:pl-[117px] mq1350:pt-28 mq1350:box-border">
        <div className="w-[1165.6px] h-[1080.3px] absolute !m-[0] top-[0px] left-[0px] flex items-center justify-center z-[0]">
          <img
            className="w-full h-full object-contain absolute left-[61px] top-[150px] [transform:scale(1.278)]"
            alt=""
            src="/group-47.svg"
          />
        </div>
        <div className="self-stretch bg-gray-500 [backdrop-filter:blur(10px)] flex flex-row items-start justify-between py-6 px-20 gap-[20px] z-[1] text-13xl border-b-[1px] border-solid border-gray-300 mq800:flex-wrap mq800:pl-10 mq800:pr-10 mq800:box-border">
          <div className="flex flex-col items-start justify-start pt-2 px-0 pb-0">
            <div className="relative tracking-[-0.02em] leading-[40px] font-medium mq450:text-lgi mq450:leading-[24px] mq800:text-7xl mq800:leading-[32px]">
              dijestify
            </div>
          </div>
          <div className="flex space-x-2">
            {/* <div className="rounded-81xl [background:linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0))] flex flex-row items-start justify-start py-4 px-[31px] whitespace-nowrap text-center text-lg border-[1px] border-solid border-skyblue">
              <a
                href="https://chromewebstore.google.com/detail/dijestify/faokihkggmdjiecnobbldcijlaflkcdg"
                target="_blank"
                className="w-[153px] relative leading-[24px] font-semibold flex items-center justify-center cursor-pointer text-white no-underline"
              >
                Add to Chrome
              </a>
            </div> */}
            <div className="rounded-81xl [background:linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0))] flex flex-row items-start justify-start py-4 px-[31px] whitespace-nowrap text-center text-lg border-[1px] border-solid border-skyblue">
              <a
                href="https://app.dijestify.com"
                className="w-[153px] relative leading-[24px] font-semibold flex items-center justify-center cursor-pointer text-white no-underline"
              >
                Login
              </a>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-[60px]">
          <div className="h-[1015px] mq800:h-[12.5em] w-[1148px] relative max-w-full">
            <div className="absolute top-[0px] left-[0px] w-full flex flex-col items-center justify-start pt-0 px-0 pb-6 box-border gap-[80px] max-w-full h-full z-[1] mq1125:gap-[40px_80px] mq800:gap-[20px_80px]">
              <div className="flex flex-col items-center justify-start py-0 px-[21px] box-border gap-[24px] max-w-full">
                <div className="w-[899px] flex flex-col items-center justify-start max-w-full">
                  <h1 className="m-0 self-stretch relative text-inherit tracking-[-0.02em] leading-[80px] font-medium font-inherit mq450:text-[36px] mq450:leading-[48px] mq800:text-29xl mq800:leading-[64px]">
                    <p className="m-0">the web’s most powerful</p>
                    <p className="m-0">reading assistant.</p>
                  </h1>
                </div>
                <div className="relative text-[20px] leading-[24px] text-silver text-left mq450:text-base mq450:leading-[19px]">
                  transforming how you absorb and retain information
                </div>
              </div>
              <div className="border-2 border-solid border-white rounded-81xl [background:linear-gradient(93.45deg,_#1bb4bc,_#5f5de7_53.13%,_#8174fd_90.63%),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0))] flex flex-row items-start justify-start py-4 px-8 whitespace-nowrap mq800:mt-[0.5em]">
                <a
                  href="https://chromewebstore.google.com/detail/dijestify/faokihkggmdjiecnobbldcijlaflkcdg"
                  target="_blank"
                  className="text-[20px] w-[153px] relative leading-[24px] font-semibold flex items-center justify-center cursor-pointer text-white no-underline"
                >
                  Add to Chrome
                </a>
              </div>
              <div className="overflow-hidden	 self-stretch h-[9em] relative rounded-3xl shrink-0 flex items-center justify-center mq800:h-[5em] mq800:mt-[0.5em]">
                <img
                  className="self-stretcmax-w-full overflow-hidden shrink-0 object-contain absolute left-[-4px] top-[4px] w-full [transform:scale(1.171)]"
                  loading="lazy"
                  alt=""
                  src="/image-20@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start max-w-full mq800:mt-[1.5em]">
          <RunningText />
          <div className="self-stretch flex flex-col items-end justify-start py-0 px-0 box-border max-w-full">
            <div className="w-[12.3px] h-[810px] overflow-hidden shrink-0 flex flex-col items-start justify-start relative gap-[3.077540159225464px] z-[3]">
              <div className="w-full h-[5px] !m-[0] absolute top-[0px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[7.7px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[15.4px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[23.1px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[30.8px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[38.5px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[46.2px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[53.9px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[61.6px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[69.2px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[76.9px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[84.6px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[92.3px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[100px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[107.7px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[115.4px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[123.1px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[130.8px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[138.5px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[146.2px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[153.9px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[161.6px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[169.3px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[177px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[184.7px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[192.3px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[200px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[207.7px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[215.4px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[223.1px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[230.8px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[238.5px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[246.2px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[253.9px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[261.6px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[269.3px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[277px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[284.7px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[292.4px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[300.1px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[307.8px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[315.4px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[323.1px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[330.8px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[338.5px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[346.2px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[353.9px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[361.6px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[369.3px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[377px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[384.7px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[392.4px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute top-[400.1px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[397.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[389.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[381.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[374.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[366.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[358.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[351.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[343.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[335.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[328px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[320.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[312.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[304.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[297.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[289.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[281.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[274.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[266.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[258.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[251px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[243.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[235.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[228px] left-[0px] flex flex-row items-start justify-start gap-[1.54px]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[220.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[212.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[204.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[197.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[189.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[181.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[174.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[166.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[158.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[151px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[143.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[135.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[127.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[120.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[112.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[104.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[97.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[89.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[81.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[74.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[66.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[58.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[51px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[43.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[35.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[27.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[20.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[12.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[4.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-2.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-10.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-18.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-25.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-33.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-41.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-49px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-56.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-64.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-72.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-79.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-87.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-95.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-102.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-110.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-118.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-126px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-133.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-141.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-149px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-156.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-164.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-172.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-179.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-187.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-195.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-202.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-210.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-218.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-226px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-233.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-241.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-249.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-256.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-264.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-272.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-279.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-287.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-295.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-302.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-310.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-318.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-326px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-333.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
              </div>
              <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-341.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
                <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
                <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start py-20 mq800:py-[0.1em] px-5 box-border relative gap-[80px] max-w-full mt-[-770px] mq1125:gap-[40px_80px] mq800:gap-[20px_80px] mq800:pb-[52px] mq800:box-border">
              <div className="flex flex-row items-start justify-start relative gap-[24px] max-w-full z-[1]">
                <div className="flex flex-col items-start justify-start gap-[8px] max-w-full">
                  <div className="flex flex-row items-start justify-start py-0 pr-[41px] pl-[41.5px]">
                    <h1 className="m-0 relative text-inherit leading-[64px] font-medium font-inherit mq450:text-10xl mq450:leading-[38px] mq800:text-19xl mq800:leading-[51px]">
                      Say goodbye to lengthy content
                    </h1>
                  </div>
                  <div className="relative text-base leading-[24px] text-silver">
                    Dijestify generates summaries using AI and revolutionize how you digest information.
                  </div>
                </div>
              </div>
              <div className="w-[1020px] flex flex-row items-start justify-start py-0 px-1 box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[24px] max-w-full z-[1]">
                  <div className="overflow-hidden rounded-xl self-stretch h-[428px] mq800:h-[5em] flex flex-row items-start justify-start py-0 pr-[33px] pl-7 mq800:mt-[0.9em] box-border max-w-full">
                    <div className="h-full flex-1 relative rounded-[25.91px] flex items-center justify-center">
                      <img
                        className="h-full flex-1 max-w-full overflow-hidden object-contain absolute left-[4px] top-[4px] w-full [transform:scale(1.284)]"
                        loading="lazy"
                        alt=""
                        src="/image-22@2x.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RunningText />
        </div>
        <div className="self-stretch flex flex-row items-start justify-start relative max-w-full">
          <div className="h-[541px] w-[578.6px] absolute !m-[0] bottom-[-77px] left-[322px] flex items-center justify-center z-[0]">
            <img
              className="h-full w-full object-contain absolute left-[-12px] top-[65px] [transform:scale(2.072)]"
              alt=""
              src="/group-46.svg"
            />
          </div>
          <div className="flex-1 flex flex-row items-start justify-start px-[21px] box-border gap-[32px] max-w-full z-[1] mq800:gap-[32px_16px] mq800:pt-[0px] mq800:pb-[52px] mq800:box-border mq1350:flex-wrap pt-[1em] mq800:pt-[0]">
            <div className="w-[702px] flex flex-col items-start justify-start pt-[81px] mq800:pt-[0px] px-0 pb-0 box-border min-w-[702px] max-w-full mq1125:min-w-full mq1350:flex-1">
              <div className="self-stretch h-[257.5px] relative max-w-full">
                <div
                  className="absolute top-[41.5px]
                left-[54px] mq800:left-[0px]
                mq800:text-center w-[648px] flex flex-col items-start justify-start gap-[32px] max-w-full"
                >
                  <h1 className="m-0 self-stretch relative text-inherit leading-[64px] font-medium font-inherit mq450:text-10xl mq450:leading-[38px] mq800:text-19xl mq800:leading-[51px]">
                    Discover news all around the world
                  </h1>
                  <div className="w-[513px] relative text-base leading-[28px] font-medium text-silver flex items-center max-w-full">
                    Stay up-to-date with news all around the globe with dijestify’s Discover feature!
                  </div>
                </div>
              </div>
            </div>
            <div className="overflow-hidden h-[461px] w-[600px] relative rounded-[17.89px] flex items-center justify-center">
              <img
                className=" w-full max-w-full object-contain absolute left-[8px] top-[6px] [transform:scale(1.13)] mq1350:flex-1"
                loading="lazy"
                alt=""
                src="/image-27@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch h-[416px] flex flex-col items-center justify-start  p-20 mq800:p-0 mq800:mt-[-2em] box-border relative gap-[40px] max-w-full z-[1] text-center mq800:h-[9em]">
          <div className="w-[1280px] !m-[0] absolute top-[80px] flex flex-col items-center justify-start gap-[40px] max-w-full">
            <div className="self-stretch flex flex-col items-center justify-start py-0 px-5 box-border gap-[8px] max-w-full">
              <h1 className="m-0 w-[687px] relative text-inherit leading-[64px] font-medium font-inherit flex items-center max-w-full mq450:text-10xl mq450:leading-[38px] mq800:text-19xl mq800:leading-[51px]">
                <span className="w-full">
                  <p className="m-0">Are you ready for this</p>
                  <p className="m-0">AI tool Powerhouse?</p>
                </span>
              </h1>
              <div className="relative text-base leading-[24px] text-silver text-left">
                Sign up for dijestify today!
              </div>
            </div>
            <div className="flex flex-row items-start justify-center gap-[32px] max-w-full text-lg mq800:flex-wrap">
              <div className="border border-solid border-white rounded-81xl [background:linear-gradient(93.45deg,_#1bb4bc,_#5f5de7_53.13%,_#8174fd_90.63%),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0))] flex flex-row items-start justify-start py-4 px-8 whitespace-nowrap">
                <a
                  href="https://chromewebstore.google.com/detail/dijestify/faokihkggmdjiecnobbldcijlaflkcdg"
                  target="_blank"
                  className="w-[153px] relative leading-[24px] font-semibold flex items-center justify-center cursor-pointer text-white no-underline"
                >
                  Add to Chrome
                </a>
              </div>
              {/* <div className="rounded-81xl [background:linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_rgba(255,_255,_255,_0))] flex flex-row items-start justify-start py-4 px-[31px] whitespace-nowrap border-[1px] border-solid border-skyblue">
                <div className="w-[164px] relative leading-[24px] font-semibold flex items-center justify-center">
                  Request a demo
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start max-w-full mq800:mt-[1.5em] text-13xl text-steelblue">
          <div className="self-stretch bg-gray-600 box-border flex flex-col items-center justify-start py-6 px-0 gap-[64px] max-w-full border-t-[1px] border-solid border-white mq450:gap-[16px_64px] mq800:gap-[32px_64px]">
            <div className="self-stretch flex flex-col items-center justify-start py-0 px-5 box-border gap-[32px] max-w-full mq450:gap-[16px_32px]">
              <div className="flex flex-col items-center justify-start gap-[8px] max-w-full">
                <div className="relative tracking-[-0.02em] leading-[40px] font-medium mq450:text-lgi mq450:leading-[24px] mq800:text-7xl mq800:leading-[32px]">
                  dijestify
                </div>
                <div className="relative text-base leading-[24px] text-silver">
                  the most powerful research assitant.
                </div>
              </div>
              {/* <div className="h-6 flex flex-row items-center justify-start gap-[24px]">
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                  loading="lazy"
                  alt=""
                  src="/social-icon.svg"
                />
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                  loading="lazy"
                  alt=""
                  src="/social-icon-1.svg"
                />
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                  loading="lazy"
                  alt=""
                  src="/social-icon-2.svg"
                />
              </div> */}
            </div>
            <div className="self-stretch flex flex-row items-center justify-center max-w-full text-center text-base text-gray-100 mq800:gap-[32px_16px]">
              <div className="flex-1 relative leading-[24px] font-medium inline-block max-w-full">
                © 2024 dijestify. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="h-[1151px] !m-[0] absolute top-[104px] left-[1428px] overflow-hidden shrink-0 flex flex-col items-start justify-start gap-[3.0941176470588005px] z-[4]">
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[7.7px] relative bg-white" />
          <div className="h-[4.6px] w-[3.1px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[9.2px] relative bg-white" />
          <div className="h-[4.6px] w-[1.5px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[7.7px] relative bg-white" />
          <div className="h-[4.6px] w-[3.1px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[9.2px] relative bg-white" />
          <div className="h-[4.6px] w-[1.5px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[7.7px] relative bg-white" />
          <div className="h-[4.6px] w-[3.1px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[9.2px] relative bg-white" />
          <div className="h-[4.6px] w-[1.5px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[7.7px] relative bg-white" />
          <div className="h-[4.6px] w-[3.1px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[9.2px] relative bg-white" />
          <div className="h-[4.6px] w-[1.5px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[7.7px] relative bg-white" />
          <div className="h-[4.6px] w-[3.1px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[9.2px] relative bg-white" />
          <div className="h-[4.6px] w-[1.5px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[7.7px] relative bg-white" />
          <div className="h-[4.6px] w-[3.1px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[9.2px] relative bg-white" />
          <div className="h-[4.6px] w-[1.5px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[7.7px] relative bg-white" />
          <div className="h-[4.6px] w-[3.1px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[9.2px] relative bg-white" />
          <div className="h-[4.6px] w-[1.5px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[7.7px] relative bg-white" />
          <div className="h-[4.6px] w-[3.1px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[9.2px] relative bg-white" />
          <div className="h-[4.6px] w-[1.5px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[7.7px] relative bg-white" />
          <div className="h-[4.6px] w-[3.1px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[9.2px] relative bg-white" />
          <div className="h-[4.6px] w-[1.5px] relative bg-white" />
        </div>
        <div className="w-[12.3px] flex flex-row items-start justify-between opacity-[0.2] gap-[1.54px]">
          <div className="h-[4.6px] w-[4.6px] relative bg-white" />
          <div className="h-[4.6px] w-[6.2px] relative bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[400.1px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[407.8px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[415.5px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[423.2px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[430.9px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[438.5px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[446.2px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[453.9px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[461.6px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[469.3px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[477px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[484.7px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[492.4px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[500.1px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[507.8px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[515.5px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[523.2px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[530.9px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[538.6px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[546.3px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[554px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[561.7px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute top-[569.3px] right-[0px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[569px] left-[0px] flex flex-row items-start justify-start gap-[1.54px]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[561.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[553.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[545.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[538.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[530.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[522.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[515.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[507.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[499.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[492px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[484.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[476.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[468.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[461.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[453.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[445.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[438.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[430.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[422.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[415.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[407.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[399.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[392px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[384.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[376.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[368.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[361.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[353.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[345.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[338.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[330.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[322.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[315.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[307.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[299.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[292px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[284.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[276.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[268.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[261.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[253.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[245.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[238.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[230.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[222.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[215px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[207.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[199.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[192px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[184.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[176.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[168.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[161.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[153.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[145.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[138.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[130.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[122.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[115px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[107.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[99.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[91.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[84.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[76.6px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[68.9px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[61.2px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[53.5px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[45.8px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[38.1px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[30.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[7.7px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[3.1px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[22.7px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[9.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[1.5px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[15px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[7.3px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] right-[0px] bottom-[0.4px] bg-white" />
        </div>
        <div className="w-full h-[5px] !m-[0] absolute right-[0px] bottom-[-0.4px] left-[0px] flex flex-row items-start justify-start gap-[1.54px] opacity-[0.2]">
          <div className="h-[calc(100%_-_0.4px)] w-[4.6px] absolute !m-[0] top-[0px] bottom-[0.4px] left-[0px] bg-white" />
          <div className="h-[calc(100%_-_0.4px)] w-[6.2px] absolute !m-[0] top-[0px] right-[-0.1px] bottom-[0.4px] bg-white" />
        </div>
      </div> */}
    </div>
  );
};

export default ChromeExtensionLandingPage;
